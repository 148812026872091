import React from "react";
import { graphql } from "gatsby";

import TRANSPARENCY from "../../../content/transparency.json";

import { useTranslations } from "../../hooks/translations";
import SEO from "../../components/seo";
import JoinUs from "../../components/about/join-us";

import styles from "./transparency.module.scss";

export const query = graphql`
  query TransparencyQuery($locale: String!) {
    translations(locale: $locale) {
      pages {
        about {
          transparency
        }
      }
      joinUs
      ...LayoutTranslations
    }
  }
`;

const TransparencyPage = () => {
  const t = useTranslations("pages.about.transparency");

  return (
    <>
      <SEO title={t.title} description={t.metaDescription} />
      <section>
        <div className="bp-container">
          <h1>{t.headingPolicies}</h1>
          <div className="bp-cards">
            {TRANSPARENCY.policies.map(({ key, url }) => (
              <a
                key={key}
                href={url}
                target="_blank"
                rel="noreferrer noopener"
                className={`bp-card bp-interactive ${styles.link} ${styles.card}`}
              >
                {t.policies[key]}
              </a>
            ))}
          </div>
        </div>
      </section>
      <section>
        <div className="bp-container">
          <h1>{t.headingPrinciples}</h1>
          <div className="bp-cards bp-3">
            {TRANSPARENCY.principles.map(({ key }) => {
              const principle = t.principles[key];

              return (
                <div key={key} className={`bp-card ${styles.card}`}>
                  <h2>{principle.title}</h2>
                  <p className={styles.paragraph}>{principle.description}</p>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <JoinUs />
    </>
  );
};

export default TransparencyPage;
